
<template>
  <Breadcrumbs main="Event" title="อัปโหลด" />
  <div class="container-fluid">
  
  <div v-if="this.is_event_permission === 'Y'">
    
    <div v-if="this.eventDetails.uploadable === 'Y'" class="col-sm-12">
      <div class="card">
        <div class="card-body">

          <div class="row">
          <div class="col-xl-4 col-sm-4" style="text-align: center">
              <div class="d-flex justify-content-center">
            <h5>{{ this.eventDetails.title }}</h5>
          </div>
          <div class="d-flex justify-content-center">
            <h4>{{ this.user.display_name }}</h4>
          </div>
          </div>
       
              <div class="col-xl-4 col-sm-4" style="text-align: center">
                QRCode เพื่อใช้ในการประชาสัมพันธ์<br>
                <vue-qrcode 
                    :value=this.qr_code
                    :options="{
                      errorCorrectionLevel: 'high',
                      width: 180,
                      quality: 0.99
                    }"
                    ></vue-qrcode>
                 <p> <a target="_blank" :href="'https://ishootrun.in.th/eventqr/'+this.$route.params.q"><u>ดาวน์โหลด QRCode ขนาดใหญ่</u></a></p>
              </div>
          
          <div class="col-xl-4 col-sm-4" style="text-align: center">
            <img
              style=" border-radius: 0.5rem;height: 130px;"
              :src="this.eventDetails.coverurl"
            />
          </div>
        </div>
          <!-- <div class="d-flex justify-content-center">
            <img
              style="width: 40%; border-radius: 0.5rem;bo"
              :src="this.eventDetails.coverurl"
            />
          </div>
          <p></p>
          <div class="d-flex justify-content-center">
            <h2>{{ this.eventDetails.title }}</h2>
          </div>
          <div class="d-flex justify-content-center">
            <h4>{{ this.user.display_name }}</h4>
          </div> -->
          <hr>
          <div class="justify-content-center">
            <div class="row">
              <div class="col-xl-6 col-sm-6" style="text-align: left;background-color: lightgray;border-radius: 1rem; margin-left: 2%;">
                <!-- <b>&nbsp;</b><br>
                <b> รูปที่ upload ทั้งหมด: {{ this.total }}</b><br>
                <p> รายชื่อรูปที่ upload สำเร็จ <a target="_blank" :href="'/upload-images/'+this.eventDetails.event_key">ตรวจสอบ</a></p> -->
                <b>&nbsp;</b><br>
              <b>คำแนะนำในการตั้งค่ากล้อง</b>
              <p>1.ตั้งค่าคุณภาพของรูปที่ถ่ายเป็นแบบ JPEG (Basic,Low,STD) เพื่อขนาดของรูปที่ไม่ใหญ่มากจนเกินไป และความเร็วในการ upload<br>
              <lu>  
                <li>ตัวอย่างการตั้งค่ากล้อง <a href="https://running.in.th/running-img/running-camera-setting-nikon.jpg" target="_blank">Nikon</a>, <a href="https://support.usa.canon.com/kb/index?page=content&id=ART173768" target="_blank">Canon</a>, <a href="https://running.in.th/running-img/running-camera-setting-sony.jpg" target="_blank">Sony</a></li>
              </lu>
              <textarea rows="10" v-model="this.eventDetails.post_caption"> </textarea>
            </p>
            
              </div>
              
              <div class="col-xl-5 col-sm-5" style="text-align: left;background-color: lightgray;border-radius: 1rem; margin-left: 4%;">
                <b>&nbsp;</b><br>
                <b>คำแนะนำในการใช้งานระบบ</b>
                
                <p>1.ขณะกำลังอัพโหลด <font  style="color: red;"><u>ต้องเปิดหน้านี้ค้างไว้</u></font> เนื่องจากระบบดึงทรัพยากรเครื่องมาใช้ให้ได้มากที่สุด เพื่อความเร็วในการ upload <br>
                   2.แนะนำใช้งานบน Google Chrome หรือ Microsoft Edge เพื่อความเร็วสูงสุดในการอัพโหลด<br>
                   3.Upload File JPEG (.jpg/.jpeg) เท่านั้น<br>
                   4.Upload File ที่มีขนาด ขั้นต่ำ 4000 px +- ได้ (ระบบจะส่งไฟล์ต้นฉบับให้นักวิ่ง) <br>
                   5.File <font  style="color: red;">ขนาดไม่เกิน 4.5 MB เพื่อความเร็วในการอัพโหลด ถ้าขนาดเกิน แนะนำให้ export รูปก่อนการ Upload <u>หากไฟล์เกิน 4.5MB ระบบจะปิดการใช้งาน Fast Uploader</u></font>
                        <lu>
                          &nbsp;<li> Quality:70</li>
                          <li> Resolution: 70</li>
                          <li> Image size: 4300px (Long Edge)</li>
                          <li> *** <u><a href="https://running.in.th/running-img/running-export-lr-setting.jpg"  target="_blank">การตั้งค่า light room เพื่อ Export</a></u></li>
                        </lu>
                   6.อัพโหลดภาพครั้งละประมาณ 1000-2000 ภาพ เพื่อความรวดเร็ว<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *** สามารถอัปโหลด ครั้งละเยอะๆ 10,000+ แล้วไปนอนได้
                        <br>
                   7.ควรตั้งชื่อรูปไม่ให้ซ้ำกัน ระบบจะข้ามการอัปโหลด ไฟล์ชื่อซ้ำ<br>
                   8.อัปโหลด รูปเข้าระบบหลังจากจบกิจกรรมไม่เกิน 24 ชม.<br>
                   9.ไม่อนุญาติให้อัปโหลด รูปเดิมซ้ำ เข้าระบบ (ไม่แต่งรูปซ้ำแล้วอัปโหลดเข้ามาอีกครั้ง) <br>
                   
                </p>
                
                <!-- <button type="button" class="btn btn-primary" @click="getTaged( this.eventDetails.event_id, this.user_display.user_id)"><vue-feather type="refresh-cw" size="15"> </vue-feather> refresh</button> -->
              </div>
              <p>&nbsp;</p>
              <div class="col-xl-4 col-sm-4" style="text-align: center">
                <b style="color: rgb(0, 0, 0)">รูปที่อัปโหลดทั้งหมด: {{ this.total }} (<a target="_blank" :href="'/upload-images/'+this.eventDetails.event_key">รายชื่อไฟล์ที่อัปโหลด</a>)</b
                >
              </div>
              <div class="col-xl-4 col-sm-4" style="text-align: center">
                <b style="color: rgb(255, 102, 0)"
                  >รอคิวแท็ก: {{ this.processing }}</b
                >
              </div>
              <div class="col-xl-4 col-sm-4" style="text-align: center">
                <b style="color: green">แท็กแล้วพร้อมจำหน่าย: {{ this.taged }}</b>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.eventDetails.uploadable === 'N'" class="col-sm-12">
      กิจกรรมนี้ ปิดการอัปโหลด
    </div>
    <div class="col-sm-12" v-if="this.eventDetails.uploadable === 'Y'"  >

    <div class="card card-btn-upload" v-if="this.eventDetails.is_hybrid==='Y'">
      <h5>กิจกรรมนี้ เป็นรูปแบบการขายแบบไฮบริด มีการใช้งาน ระบบเครดิต <br>เครดิตคงเหลือ {{ this.credit_balance }}</h5>
      <a target="_blank" href="/credit-create"><b><u>เติมเครดิต</u></b></a>
        *** เครดิดอาจจะถูกปรับให้เหมาะสมได้ในภายหลัง เพื่อสมดุลของช่างภาพ และระบบ
    </div>
    <div class="card card-btn-upload">
      &nbsp;
      <div class="row">
       
        <div class="col-xl-3 col-sm-6">
          <h5>รูปที่อัปโหลด: {{ upload_store.selectedFiles.length }}</h5>
        </div>
        <div class="col-xl-3 col-sm-6">
          <h5>กำลังอัปโหลด: {{ upload_store.uploading_count }}</h5>
        </div>
        <div class="col-xl-3 col-sm-6">
          <h5 style="color: green">
            อัปโหลดสำเร็จ: {{ upload_store.success_count }}
          </h5>
        </div>
        <div class="col-xl-3 col-sm-6">
          <h5 style="color: red">
            พบข้อผิดพลาด: {{ upload_store.failed_count }}
          </h5>
        </div>
      </div>

      <div class="card-body btn-showcase">
        <label 
          class="btn btn-primary"
          :class="{ disabled: upload_store.isUploading }"
          for="selectBtn"
        >
        <vue-feather type="upload-cloud" size="18"></vue-feather> เลือกไฟล์อัปโหลด
        </label>
        <button
          class="btn btn-success"
          :class="{ disabled: upload_store.isUploading }"
          @click.prevent="
            upload_store.uploadSelectedFiles(
              this.eventDetails.event_key,
              this.eventDetails.event_watermark_hori,
              this.eventDetails.event_watermark_vertical,
              this.user.user_id,
              this.eventDetails.event_id,
              this.eventDetails.server_upload_url_text,
              this.eventDetails.server_upload_url_aws,
              this.eventDetails.is_free_download,
              this.eventDetails.is_add_watermark_for_free_download,
              this.eventDetails.is_mhao,
              this.eventDetails.is_hybrid,
              this.credit_balance
            )
          "
          type="button"
          data-bs-toggle="tooltip"
          title="Upload"
        >
          {{ upload_store.failed_count > 0 ? "Retry" : "เริ่มอัปโหลด" }}
        </button>
        <button
          class="btn btn-info"
          :class="{ disabled: upload_store.isUploading }"
          type="button"
          data-bs-toggle="tooltip"
          title="Clear"
          @click.prevent="upload_store.clearFiles"
        >
        <vue-feather type="refresh-cw" size="18"></vue-feather>  รีเซ็ต
        </button>

        <!-- <a href="#">
          <button
            class="btn btn-danger"
            type="button"
            data-bs-toggle="tooltip"
            title="Cancel"
          >
            ยกเลิกการอัปโหลด
          </button></a
        > -->
      </div>
    </div>
    </div>

    <div class="row" v-if="upload_store.selectedFiles.length">
    <div class="col-sm-12">
      <div class="card">
        <div class="my-gallery card-body row gallery-with-description">
          <div
            class="col-xl-4 col-sm-6 upload-item"
            v-for="(file, index) in upload_store.selectedFiles"
            :key="index"
          >
            <div
              :class="{
                'failed-border': file.status == 'failed',
                'success-border': file.status == 'success',
              }"
            >
              <div v-if="file.status !== 'success'">
                <span v-if="file.status == 'pending'">
                  <b>{{ file.file.name }}[Waiting]</b>
                </span>
                <span v-if="file.status == 'failed'">
                  <b class="failed">{{ file.file.name }}[Failed]</b>
                </span>
                <span v-if="file.status === 'uploading'">
                  <!-- uploading...{{ file.percentage }}% -->

                  <b class="uploading"
                    >{{ file.file.name }}[Upload{{ file.percentage }}%]</b
                  >
                </span>
              </div>
              <div v-if="file.status === 'success'">
                <b class="success">{{ file.file.name }}[Completed]</b>
              </div>
            </div>
          </div>
        </div>
        &nbsp;
      </div>
    </div>
    </div>

    
  </div>
  <div v-if="this.is_event_permission === 'CHECKING'">
        <div class="d-flex justify-content-center">authorized checking...</div>
  </div>
  <div v-if="this.is_event_permission === 'N'">
         <div class="d-flex justify-content-center">this page is not authorized</div>
         
         <div v-if="this.is_mhao !== 'Y' && this.loadding === 'N'">
            <div class="d-flex justify-content-center">
                event is not currect!
            </div>
          </div>
  </div>

</div>
  <div></div>
  <!-- Hidden file input -->
  <input
    id="selectBtn"
    type="file"
    multiple
    accept=".jpg, .jpeg"
    title="Upload file"
    @change="upload_store.onSelectFiles"
  />

  <!-- Buttons -->
</template>
  <script>
import { useUploadStore } from "./upload";
//import { useUserStore } from "../../store/modules/user";
import axios from "axios";
import language from '@/components/header/language.vue';
export default {
  components: { language },
  setup() {
    const upload_store = useUploadStore();
    //const user_store = useUserStore();
    //user_store.loadFromLocalStorage();

    return { upload_store };
  },
  data() {
    return {
      eventDetails: {},
      selectedFiles: [],
      processing: 0,
      taged: 0,
      total: 0,
      //user_display: {},
      loading: true,
      user: {},
      qr_code: "https://ishootrun.in.th/"+this.$route.params.q,
      is_event_permission: "CHECKING",
      credit_balance: -1

    };
  },
  async mounted() {

    try {

      this.user = JSON.parse(localStorage.getItem('user'))
      let _is_event_permission = await this.event_permission();

      //console.log("_is_event_permission:"+_is_event_permission)
      if(_is_event_permission === 'Y'){
        let eventDetail = await this.getEventDetail(this.$route.params.q);
        await this.getTaged(eventDetail.event_id, this.user.user_id);
        if(this.eventDetails.is_hybrid === 'Y'){
          await this.getCredit()
        }
      }
    } catch (error) {
      alert("พบข้อผิดพลาดกรุณาติดต่อผู้ดูแลระบบ หรือลองอีกครั้ง", error);
      location.reload();
    }

  },
  methods: {
    async event_permission() {
      
      try{
        let data = {
          event_key: this.$route.params.q,
        };
        const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
        const response = await axios.post(
          "v1/order/event-permission",
          data,
          {
            headers
          }
        );
        //console.log(JSON.stringify(response))
        if (response.data.status === "success") {
          this.is_event_permission = response.data.body.event_permission
          return response.data.body.event_permission;
        } else {
          alert("การตรวจสอบสิทธิ์ พบข้อผิดพลาด! ");
          return "N"
        }
          
      } catch (error) {
        alert("การตรวจสอบสิทธิ์ พบข้อผิดพลาด กรุณาแจ้งผู้ดูแลระบบ")
        return "N"
      }
      
    },


    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
     // console.log("res.data", JSON.stringify(res.data));
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        this.eventPackage = res.data.package;
        return res.data;
      } else {
        alert("[EventDetail]พบข้อผิดพลาด!");
        this.$router.push({ path: "/" });
      }
    },
    async getCredit(event_id, photographer_id) {
      const data = {
        event_id: event_id,
        photographer_id: photographer_id,
      };
      const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("jwt")
      };
      const res = await axios.post("/v1/utils/credit-balance", data,{headers});
      if (res.data.status === "success") {
        this.credit_balance = res.data.body.credit_balance;
      } else {
        alert("[getCredit]พบข้อผิดพลาด!");
        //this.$router.push({ path: "/" });
      }
    },
    async getTaged(event_id, photographer_id) {
      const data = {
        event_id: event_id,
        photographer_id: photographer_id,
      };
      const res = await axios.post("/v1/events/taged", data);
      if (res.data.status === "success") {
        this.processing = res.data.processing;
        this.taged = res.data.taged;
        this.total = res.data.total;
      } else {
        alert("[Taged]พบข้อผิดพลาด!");
        this.$router.push({ path: "/" });
      }
    },
    cancel() {
      this.$router.push({ path: "/" });
    },
   drawImage(context, image, x, y, width, height, radius = 20) {
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.shadowBlur = 0;
      context.shadowColor = "#00000040";
      context.lineWidth = 0;
      context.beginPath();
      context.moveTo(x + radius, y);
      context.arcTo(x + width, y, x + width, y + height, radius);
      context.arcTo(x + width, y + height, x, y + height, radius);
      context.arcTo(x, y + height, x, y, radius);
      context.arcTo(x, y, x + width, y, radius);
      context.closePath();
      context.strokeStyle = "#fff";
      context.stroke();
      context.clip();
      context.fillStyle = "#fff";
      context.fillRect(x, x, width, height);
      context.drawImage(image, x, x, width, height);
    },
  },
  };
</script>
  <style scoped lang="scss">
#selectBtn {
  display: none;
}
.card-btn-upload {
  text-align: center;
}
.card-file-list {
  text-align: center;
}

.upload-item {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.5rem;
  //padding: 0.5rem;
  //font-size: smaller;
  float: left;
  padding: 5px;
  text-align: center;
}
.drop-container:hover {
  background: #979696ad;
}
.upload-item:hover {
  background: #e0e0e0ad;
  cursor: pointer;
  border: 1px dashed rgba(124, 2, 2, 0.616);
}

.buttons {
  display: flex;
  gap: 1rem;

  &.centered {
    justify-content: center;
  }
}

.button {
  cursor: pointer;

  color: white;
  background: #809af0;
  text-decoration: none;

  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 2px;

  &:hover {
    background: #3f6cff;
  }

  &.disabled {
    cursor: default;
    background: lightgray;
    pointer-events: none;
  }
}

.button-danger {
  background: #f08080;

  &:hover {
    background: #f65959;
  }
}

.button-upload {
  background: #00d591;

  &:hover {
    background: #02b97f;
  }
}

.failed {
  color: red;
}

.success {
  color: green;
}

.uploading {
  color: rgb(255, 102, 0);
}

.failed-border {
  border-color: red;
}

textarea
{
  width:100%;
  background-color: #ffffffea;
  border-radius: .7rem;
}
.textwrapper
{
  border:1px solid #999999;
  margin:5px 0;
  padding:3px;
}
</style>