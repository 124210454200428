<template>
  <div class="row">&nbsp;</div>
  <div v-if="this.page_permission === 'Y'">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="row">
            <!-- <WelcomeCard /> -->
            <div class="col-xxl-12">
              <div class="row widget-grid">
                <div class="card profile-box">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="greeting-user">
                          <h4 class="f-w-600 mb-0">
                            <vue-feather
                              type="bar-chart"
                              size="36"
                            ></vue-feather>
                             DASHBOARD
                          </h4>
                          <p>&nbsp;</p>
                          <!-- <p>{{ this.eventDetails.event_date }},{{ this.eventDetails.province }}</p> -->

                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="cartoon">
                      <img
                        class="img-fluid"
                        src="@/assets/images/dashboard/cartoon.svg"
                        alt="vector women with leptop"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5
                  v-if="
                    this.user.role_name === 'system' ||
                    this.user.role_name === 'systemadmin'
                  "
                  class="m-0"
                >
                  <b
                    >Tag Proces
                    <a :href="'/event-adjustment-admin/' + this.$route.params.q"
                      >[กิจกรรม/ช่างภาพ]</a
                    ></b
                  >
                </h5>
                <h5 v-else class="m-0">
                  <b
                    >Tag Proces
                    <a :href="'/event-adjustment/' + this.$route.params.q"
                      >[กิจกรรม/ช่างภาพ]</a
                    ></b
                  >
                </h5>
                <button
                  type="button"
                  @click.prevent="systemTagProcess()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>วันที่จัดกิจกรรม</b></th>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <th style="text-align: center"><b>รูปทั้งหมด</b></th>
                    <th style="text-align: center"><b>แท็กแล้ว</b></th>
                    <th style="text-align: center"><b>กำลังแท็ก</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_tag_process === 'Y'">
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_tag_process.length === 0 &&
                    this.loadding_tag_process === 'N'
                  "
                >
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_tag_process.length !== 0">
                  <tr v-for="item in this.data_tag_process" :key="item">
                    <td scope="row" style="text-align: center">
                      {{ item.event_date }}
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.title }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ Number(item.taged) + Number(item.process) }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.taged }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.process }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>สรุปยอดสั่งซื้อ</b></h5>
                <button
                  type="button"
                  @click.prevent="reportSymmery()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>วันที่จัดกิจกรรม</b></th>
                    <th style="text-align: left"><b>กิจกรรม</b></th>
                    <th style="text-align: center"><b>รายรับ</b></th>
                    <th style="text-align: center"><b>ภาษีมูลค่าเพิ่ม</b></th>
                    <th style="text-align: center"><b>รายได้หลักหักภาษี</b></th>
                    <th style="text-align: center"><b>ส่วนแบ่งช่างภาพ</b></th>
                    <th style="text-align: center"><b>ออเดอร์</b></th>
                    <th style="text-align: center"><b>รูปที่ขาย</b></th>
                    <th style="text-align: center"><b>ส่วนแบ่งผู้จัด</b></th>
                    <th style="text-align: center"><b>ส่วนแบ่งผู้ดูแล</b></th>
                    <th style="text-align: center"><b>ส่วนแบ่งระบบ</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_report_summery === 'Y'">
                  <tr>
                    <td colspan="16">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_report_summery.length === 0 &&
                    this.loadding_report_summery === 'N'
                  "
                >
                  <tr>
                    <td colspan="16">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_report_summery.length !== 0">
                  <tr v-for="item in this.data_report_summery" :key="item">
                    <td style="text-align: center">
                      {{ item.event_date }}
                    </td>
                    <td scope="row" style="text-align: left">
                      {{ item.title }}
                    </td>
                    <td style="text-align: center">
                      100% <br />
                      {{ Number(item.amount).toFixed(2) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.vat }}% <br />
                      {{ item.net_vat }}
                    </td>
                    <td style="text-align: center">
                      100% <br />
                      {{ item.amount-item.net_vat }}
                    </td>
                    <td style="text-align: center">
                      {{ item.photographer_share }}% <br />
                      {{ Number(item.net_photographer).toFixed(2) }}
                    </td>
                    <td style="text-align: center">
                      {{ item.order_count }}
                    </td>
                    <td style="text-align: center">
                      {{ item.photo_count }}
                    </td>
                    <td style="text-align: center">
                      {{ item.organizer_share }}% <br />{{
                        item.organizershare_amt
                      }}
                    </td>
                    <td style="text-align: center">
                      {{ item.salespersonshare }}% <br />{{
                        item.salespersonshare_amt
                      }}
                    </td>
                    <td style="text-align: center">
                      {{ item.system_share }}% <br />{{ item.system_amt }}
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="this.data_report_summery.length !== 0">
                  <tr v-for="item in this.data_report_summery" :key="item">
                    <td
                      colspan="16"
                      style="text-align: left; font-size: xx-small"
                      v-if="item.is_mhao === 'Y'"
                    >
                      <b
                        >Note: [{{ item.event_date }}:{{ item.title }}]
                        ตัดรอบงานเหมา: {{ item.date_now }}</b
                      >
                      <br />
                      รายรับ(100%): {{ Number(item.amount).toFixed(2) }}<br />
                      ภาษีมูลค่าเพิ่ม({{ item.vat }}%): {{ item.net_vat }}<br />
                      ช่างภาพ({{ item.photographer_share }}%):
                      {{ item.net_photographer }}<br />
                      ผู้จัด({{ item.organizer_share }}%):
                      {{ item.organizershare_amt }}<br />
                      ผู้ดูแล({{ item.salespersonshare }}%):
                      {{ item.salespersonshare_amt }}<br />
                      ระบบ( {{ item.system_share }}%):{{ item.system_amt
                      }}<br />
                      <b><u>ยอดโอนรวม:
                          {{
                            Number(
                              item.net_photographer +
                                item.organizershare_amt +
                                item.salespersonshare_amt
                            ).toFixed(2)
                          }}</u
                        ></b
                      ><br>OPN: 
                      {{
                            Number(
                              item.net_photographer +
                                item.organizershare_amt +
                                item.salespersonshare_amt
                            ).toFixed(2)
                          }}+30=*{{
                            Number(
                              30+
                              item.net_photographer +
                                item.organizershare_amt +
                                item.salespersonshare_amt
                            ).toFixed(2)
                          }}*
                      <br>
                      <hr v-if="this.data_get_round_one_amt.round1_amt===null">
                      <button type="button" v-if="this.data_get_round_one_amt.round1_amt===null"
                          @click.prevent="saveRoundOneAmt(Number(
                              item.net_photographer +
                                item.organizershare_amt +
                                item.salespersonshare_amt
                            ).toFixed(2))"
                          class="btn btn-primary"
                        >
                          <vue-feather type="save" size="18"> </vue-feather>
                          ตัดรอบแรกงานเหมา
                        </button><br>
                    </td>
                  </tr>
                  <tr v-for="item in this.data_report_summery" :key="item">
                    <td
                      colspan="16"
                      style="text-align: left; font-size: xx-small"
                      v-if="item.is_mhao === 'Y' && this.data_get_round_one_amt.round1_amt !== null"
                    >
                    <b>Note: ข้อมูลการตัดรอบโอน รอบแรก</b><br>
                      ยอดโอนรวม: {{ Number(this.data_get_round_one_amt.round1_amt).toFixed(2) }}<br />
                      วันที่: {{ this.data_get_round_one_amt.round1_create_date }}<br />
                      เจ้าหน้าที่: {{ this.data_get_round_one_amt.round1_user_id }}<br />
                      <u>ส่วนต่างโอนเพิ่มรอบสอง {{ (Number(
                              item.net_photographer +
                                item.organizershare_amt +
                                item.salespersonshare_amt
                            )-this.data_get_round_one_amt.round1_amt).toFixed(2) }}</u><br />
                     
                          <br>
                          
                    </td>
                    
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>สรุปข้อมูลช่างภาพ</b></h5>
                <button
                  type="button"
                  @click.prevent="loadOrderImageByUser()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>ลำดับ</b></th>
                    <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                    <th style="text-align: left"><b>ชื่อ</b></th>
                    <th style="text-align: center"><b>จำนวนที่อัปโหลด</b></th>
                    <th style="text-align: center"><b>จำนวนยอดขาย</b></th>
                    <th style="text-align: center"><b>จำนวนยอดขาย(%)</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_order_image_by_user === 'Y'">
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_order_image_by_user.length === 0 &&
                    this.loadding_order_image_by_user === 'N'
                  "
                >
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_order_image_by_user.length !== 0">
                  <tr
                    v-for="(item, index) in this.data_order_image_by_user"
                    :key="item"
                  >
                    <td scope="row" style="text-align: center">
                      {{ index + 1 }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.user_id_text }}
                    </td>
                    <td style="text-align: left">
                      {{ item.display_name }}
                    </td>
                    <td style="text-align: center">
                      {{ item.image_upload_count }}
                    </td>
                    <td style="text-align: center">
                      {{ item.image_sale_count }}
                    </td>
                    <td style="text-align: center">
                      {{ Number(item.percen).toFixed(2) }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12" v-if="this.is_mhao === 'Y'">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>ข้อมูลสรุปคะแนนช่างภาพ</b></h5>
                <br />

                <button
                  type="button"
                  @click.prevent="reportPerformance()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
              <p style="text-align: left; font-size: xx-small">
                <ui>
                  <li>มีรูปที่ขายได้ใน Order เหมา นับ 1 คะแนน</li>
                  <li>มีรูปที่ขายได้ใน Order แยก นับรูปละ 1 คะแนน</li>
                </ui>
              </p>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>ลำดับ</b></th>
                    <th style="text-align: center"><b>รหัสช่างภาพ</b></th>
                    <th style="text-align: left"><b>ช่างภาพ</b></th>
                    <th style="text-align: center"><b>ออเดอร์แยก</b></th>
                    <th style="text-align: center"><b>ออเดอร์เหมา</b></th>
                    <th style="text-align: center"><b>รวมคะแนน</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_report_performance === 'Y'">
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_report_performance.length === 0 &&
                    this.loadding_report_performance === 'N'
                  "
                >
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_report_performance.length !== 0">
                  <tr
                    v-for="(item, index) in this.data_report_performance"
                    :key="item"
                  >
                    <td scope="row" style="text-align: center">
                      {{ index + 1 }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.user_id_text }}
                    </td>
                    <td style="text-align: left">
                      {{ item.display_name }}
                    </td>
                    <td style="text-align: center">
                      {{ item.score - item.mhao_score }}
                    </td>
                    <td style="text-align: center">
                      {{ item.mhao_score }}
                    </td>
                    <td style="text-align: center">
                      {{ item.score }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0">
                  <b>ข้อมูลสรุปยอดขายตามจำนวนภาพของออเดอร์</b>
                </h5>
                <br />

                <button
                  type="button"
                  @click.prevent="reportOrderSummery()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
              <p style="text-align: left; font-size: xx-small">
                <ui>
                  <li>ยอดขายแบ่งตามจำนวนภาพภายในออเดอร์</li>
                  <li v-if="this.is_mhao === 'Y'">
                    special condition คือออเดอร์ที่สร้างโดยระบบเพิ่มเติม
                    อาทิเช่น <br />&nbsp;&nbsp;&nbsp;&nbsp; > นักวิ่งเหมาแล้ว
                    และส่งรูปเพิ่มเติมให้นักวิ่ง หรือ ช่างภาพ upload
                    เข้ามาในระบบหลังจากนักวิ่งเหมาไปแล้ว
                    ระบบสร้างออเดอร์เพื่อส่งรูปเพิ่มเติมให้กับนักวิ่ง
                    <br />&nbsp;&nbsp;&nbsp;&nbsp; >
                    ออเดอร์พิเศษให้กับผู้จัดกิจกรรม
                    ตามเงือนไขที่ตกลงกับผู้จัดแต่ละกิจกรรม(ถ้ามี)
                  </li>
                </ui>
              </p>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center"><b>ลำดับ</b></th>
                    <th style="text-align: center"><b>ราคาขาย</b></th>
                    <th style="text-align: center"><b>จำนวนออเดอร์</b></th>
                    <th style="text-align: center"><b>จำนวนรูป</b></th>
                    <th style="text-align: center"><b>ยอดขาย</b></th>
                    <th style="text-align: center"><b>หมายเหตุ</b></th>
                  </tr>
                </thead>
                <tbody v-if="this.loadding_report_order_summery === 'Y'">
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        <img
                          style="align-items: center; width: 60px"
                          src="../../assets/images/running_loading.svg"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-if="
                    this.data_report_order_summery.length === 0 &&
                    this.loadding_report_order_summery === 'N'
                  "
                >
                  <tr>
                    <td colspan="6">
                      <div class="d-flex justify-content-center">
                        ไม่พบข้อมูล
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_report_order_summery.length !== 0">
                  <tr
                    v-for="(item, index) in this.data_report_order_summery"
                    :key="item"
                  >
                    <td scope="row" style="text-align: center">
                      {{ index + 1 }}
                    </td>
                    <td scope="row" style="text-align: center">
                      {{ item.amount }}
                    </td>
                    <td style="text-align: center">
                      {{ item.order_count }}
                    </td>
                    <td style="text-align: center">
                      {{
                            item.is_mhao === "Y"
                              ? "&infin;"
                              : item.amount === this.mhao_price &&
                                item.is_mhao === "N"
                              ? "&infin;"
                              : item.image_length
                          }}
                    </td>

                    <td style="text-align: center">
                      {{ Number(item.amount * item.order_count).toFixed(2) }}
                    </td>
                    <td
                      scope="row"
                      style="text-align: left; font-size: xx-small"
                    >
                      {{
                        item.amount === 0
                          ? "[special condition]"
                          : item.amount === this.mhao_price &&
                            item.is_mhao === "Y"
                          ? "แพ็กเกจ เหมา"
                          : item.amount === this.mhao_price &&
                            item.is_mhao === "N"
                          ? "เลือกรูปเกินราคาเหมา"
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0">
                  <b>หมายเลขบัญชีช่างภาพ</b>
                </h5>
                <br />

                <button
                  type="button"
                  @click.prevent="reportAccount()"
                  class="btn btn-primary"
                >
                  <vue-feather type="refresh-cw" size="18"> </vue-feather>
                  Refresh
                </button>
              </div>
              
            </div>
            <div class="table-responsive">
              <span v-for="item in this.data_report_account" :key="item">
                {{item.prefix}}-{{ item.name }}<br>
                
              </span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>

  <div v-if="this.page_permission === 'N'">
    <div class="d-flex justify-content-center">this page is not authorized</div>
  </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loadding: "N",
      loadding_order_image_by_user: "N",
      data_order_image_by_user: [],

      loadding_tag_process: "Y",
      data_tag_process: [],

      loadding_report_summery: "Y",
      data_report_summery: [],
      data_get_round_one_amt: {},

      loadding_report_performance: "N",
      data_report_performance: [],

      loadding_report_order_summery: "N",
      data_report_order_summery: [],

      data_report_account: [],

      user: {},
      page_permission: null,
      is_mhao: "N",
      mhao_price: 1000,
      token: localStorage.getItem("jwt")
    };
  },
  async created(){
    this.token = localStorage.getItem("jwt")
  },
  async mounted() {
    try {
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post("/v1/partner/permission", data, {
        headers
      });
      if (response.data.status === "success") {
        let bodyPermission = JSON.parse(JSON.stringify(response.data.body));
        this.page_permission = bodyPermission.permission;

        this.user = JSON.parse(localStorage.getItem("user"));

        if (this.page_permission === "Y") {
          this.systemTagProcess();
          this.loadOrderImageByUser();
          this.reportSymmery();
          this.reportPerformance();
          this.reportOrderSummery();
        }
      } else {
        alert("permission is not recognized by admin");
      }
    } catch (error) {
      alert("login พบข้อผิดพลาด กรุณาแจ้งผู้ดูแลระบบ");
      //this.$router.push({ path: "/auth/login" });
    }
  },
  methods: {
    async loadOrderImageByUser() {
      this.loadding_order_image_by_user = "Y";
      this.data_order_image_by_user = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/running-order-image-by-user",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_order_image_by_user = response.data.body;

        this.loadding_order_image_by_user = "N";
      } else {
        alert("Load order พบข้อผิดพลาด!");
      }
    },

    async systemTagProcess() {
      this.loadding_tag_process = "Y";
      this.data_tag_process = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const response = await axios.post("v1/admin/system-dashboard-tag", data);
      if (response.data.status === "success") {
        this.data_tag_process = response.data.body;
        this.is_mhao = response.data.body[0].is_mhao;
        this.mhao_price = response.data.body[0].mhao_price;

        this.loadding_tag_process = "N";
      } else {
        alert("systemTagProcess พบข้อผิดพลาด!");
      }
    },

    async saveRoundOneAmt(_amt){
      if(confirm('คุณต้องการตัดยอดสำหรับครั้งแรก?')){
        this.loadding_report_summery = "Y";
        let data = {
          event_key: this.$route.params.q,
          amt: _amt
        };
	const headers = {
         'Authorization': 'Bearer ' + this.token
      	};
        const response = await axios.post(
          "v1/admin/save-round-one-amt",
          data,
          {
            headers
          }
        );
        if (response.data.status === "success") {
          alert(response.data.message)

          this.reportSymmery();
        } else {
          alert("saveRoundOneAmt พบข้อผิดพลาด! "+response.data.message);
        }
        }
      
    },

    async reportSymmery() {
      this.loadding_report_summery = "Y";
      this.data_report_summery = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/dashboard-report-summery",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_report_summery = response.data.body;

        this.loadding_report_summery = "N";

        //load การตัดรอบแรก
        this.get_round_one_amt();
      } else {
        alert("reportSymmery พบข้อผิดพลาด! ");
      }
    },

    async get_round_one_amt(){
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/get-round-one-amt",
        data,
        {
          headers
        }
      );
      if (response.data.status === "success") {
        this.data_get_round_one_amt = response.data.body[0];
      } else {
        alert("data_get_round_one_amt พบข้อผิดพลาด! ");
      }
    },
    async reportPerformance() {
      this.loadding_report_performance = "Y";
      this.data_report_performance = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/dashboard-report-performance",
        data,
        {
          headers
        }
      );

      if (response.data.status === "success") {
        this.data_report_performance = response.data.body;

        this.loadding_report_performance = "N";
      } else {
        alert("reportPerformance พบข้อผิดพลาด! ");
      }
    },
    async reportAccount() {
      this.data_report_account = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/running-user-bankaccount",
        data,
        {
          headers
        }
      );

      if (response.data.status === "success") {
        this.data_report_account = response.data.body;

      } 
    },
    async reportOrderSummery() {
      this.loadding_report_order_summery = "Y";
      this.data_report_order_summery = [];
      let data = {
        event_key: this.$route.params.q,
      };
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.post(
        "v1/admin/dashboard-report-order-summery",
        data,
        {
          headers
        }
      );

      if (response.data.status === "success") {
        this.data_report_order_summery = response.data.body;

        this.loadding_report_order_summery = "N";
      } else {
        alert("reportOrderSummery พบข้อผิดพลาด! ");
      }
    },
  },
};
</script>
