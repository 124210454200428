import { defineStore } from "pinia";

import { ref, computed } from 'vue'

import axios from 'axios'

export const useUploadStore_hl = defineStore('useUploadStore_hl', () => {

  const selectedFiles = ref([]);

  const isUploading = computed(() =>
    selectedFiles.value.some((file) => file.status == "uploading")

  );

  const pending_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "pending").length

  );

  const failed_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "failed").length

  );

  const uploading_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "uploading").length

  );

  const success_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "success").length

  );

  const onSelectFiles = (event) => {
    const target = event.target;
    if (target.files === null) {
      return;
    }

    clearFiles();

    Array.from(target.files).forEach((file) => {
      selectedFiles.value.push({
        file: file,
        percentage: 0,
        status: "pending",
      });
    });
  };

  const clearFiles = () => (selectedFiles.value = []);

  const uploadFile = (file, onUploadProgress, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download) => {

    let formData = new FormData();

    formData.append("file", file);
    formData.append('photographer_id', user_id);
    //formData.append('event_id', event_id);
    formData.append('event_key', event_key);
    //formData.append('event_watermark_hori', event_watermark_hori);
    //formData.append('event_watermark_vertical', event_watermark_vertical);
    //formData.append('is_free_download', is_free_download);
    //formData.append('is_add_watermark_for_free_download', is_add_watermark_for_free_download);
    
    let url_hl = "/v1/upload/image-highlight"
    if(Number(file.size/10000) < 470){
      //upload aws
      //console.log("upload to aws:"+server_upload_url_aws)
      return axios.post(url_hl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    }else{
      //upload default server
      //console.log("upload to :"+server_upload_url_text)
      return axios.post(url_hl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    }
  };

  const sleep_only = async () => {

    return new Promise((resolve, reject) => {
      setTimeout(() => {


        resolve(true)
      }, 500)

    });


  }

  const sleep_upload_slow_upload = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {


        resolve(true)
      }, 500)

    });


  }

  const call_upload_file = (file, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download,is_add_watermark_for_free_download) => {

    return new Promise((resolve, reject) => {

      //console.log("uploadFile: " + file.file.name)
      file.status = "uploading";
      file.percentage = 0;
      uploadFile(file.file, (event) => {
        file.percentage = Math.round((100 * event.loaded) / event.total);
      }, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download,is_add_watermark_for_free_download)
        .then((response) => {
          file.status = "success";
          resolve(true)
        })
        .catch(() => {
          file.status = "failed";
          resolve(false)
        });

    });


  }

  const uploadSelectedFiles = async (event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download) => {

    
    //let check_uploading = selectedFiles.value.some((file) => file.status == "uploading")
    //let check_pending = selectedFiles.value.filter(file => file.status == "pending").length;
    let check_failed = selectedFiles.value.filter(file => file.status == "failed").length
    let check_success = selectedFiles.value.filter(file => file.status == "success").length
    if (check_success === selectedFiles.value.length) {
      if(check_success===0){
        alert("เลือกไฟล์อัปโหลด และกดเริ่มอัปโหลด")
      }else{
        alert("ไฟล์ Upload สำเร็จท้ั้งหมด")
      }
      

    } else {

      if (check_failed > 0) {
        //retry only fial
        let counter = 0
        let array_size = selectedFiles.value.length;

        while (counter < array_size) {
          let _uploading_count = selectedFiles.value.filter(file => file.status == "uploading").length
          if(_uploading_count <= 6){

            if (selectedFiles.value.length > 0) {
              
              let file = selectedFiles.value[counter];
              if(file.status !== 'success'){
                call_upload_file(file, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download)
              }
            }
            counter++
        }else{
          await sleep_upload_slow_upload();
        }

        }
        
      } else {

        let counter = 0;
        let array_size = selectedFiles.value.length;
        let thead_upload = 6;
        while (counter < array_size) {
          let _uploading_count = selectedFiles.value.filter(file => file.status == "uploading").length

          if(_uploading_count <= thead_upload){
            if (selectedFiles.value.length > 0) { 
              let file = selectedFiles.value[counter];
              if(file.status !== 'success'){
                call_upload_file(file, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download)
                //console.log("while result counter:[" + counter + "]" + _sleep_r)
                //let _sleep_only_r = await sleep_only()
              }
            }
            counter++
        }else{
          await sleep_upload_slow_upload();
        }

        }

      }

    }

  }



  return { selectedFiles, isUploading, pending_count, uploading_count, failed_count, success_count, onSelectFiles, clearFiles, uploadSelectedFiles }
});