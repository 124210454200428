<template>
  <div class="row">&nbsp;</div>
  <div v-if="this.user.role_name === 'system' || this.user.role_name === 'systemadmin' || this.user.role_name === 'administrator' || this.user.role_name === 'supper-vip'">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="row">
            <!-- <WelcomeCard /> -->
            <div class="col-xxl-12">
              <div class="row widget-grid">
                <div class="card profile-box">
                  <div class="card-body">
                    <div class="media">
                      <div class="media-body">
                        <div class="greeting-user">
                          <h4 class="f-w-600 mb-0">
                            <vue-feather
                              type="server"
                              size="25"
                            ></vue-feather>
                            CREATE EVENT
                          </h4>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                    <div class="cartoon">
                      <img
                        class="img-fluid"
                        src="@/assets/images/dashboard/cartoon.svg"
                        alt="vector women with leptop"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <form @submit.prevent="create_event" class="needs-validation">
                <h5>ข้อมูลกิจกรรม</h5>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-floating mb-3">
                        <input type="text" minlength="5" maxlength="80"  required v-model="title" class="form-control" id="event_name">
                        <label for="event_name">ชื่อกิจกรรม</label>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <span>URL สำหรับเข้าโหลดรูปภาพ: http://ishootrun.in.th/<u style="color: red;">{{ this.event_key }}</u></span> 
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon3">URL https://ishootrun.in.th/</span>
                        <input type="text" pattern="[a-z0-9]+"   class="form-control" @blur="check_duplช่างicate_event"  v-model="event_key"  minlength="5" maxlength="30" required  placeholder="ตัวอักษรภาษาอังกฤษตัวเล็ก ติดกัน ไม่มีช่องว่าง" aria-describedby="basic-addon3">
                        <span style="color: red;align-items: center">{{ check_duplicate_message }}</span>
                      </div> 
                      
                    </div>

                    
                    <div class="col-sm-12 col-lg-6">
                      <div class="input-group mb-3 ">
                      <span class="input-group-text"   id="basic-addon1">วันที่จัดกิจกรรม</span>
                      <input type="text" class="form-control" minlength="10" v-model="event_date" maxlength="10" required placeholder="2025-MM-DD" >
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                      <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">สถานที่จัดกิจกรรม</span>
                      <input type="text" class="form-control" minlength="2" v-model="province" maxlength="50" required placeholder="สถานที่ จ.xxx" >
                    </div>
                  </div>


                  <div class="col-sm-12 col-lg-6">
                      <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">วันที่ เวลา เปิดจำหน่าย</span>
                      <input type="text" class="form-control" minlength="16" v-model="on_sale_start" maxlength="16" required placeholder="2025-MM-DD HH:mm" >
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                      <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">วันที่ เวลา ปิดจำหน่าย</span>
                      <input type="text" class="form-control" minlength="16" v-model="on_sale_end" maxlength="16" required placeholder="2025-MM-DD HH:mm" >
                    </div>
                  </div>
                 
                  <div class="col-sm-12 col-lg-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">ส่วนแบ่งระบบ</span>
                      <input type="number" class="form-control" v-model="system_share" required placeholder="25" >
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">ส่วนแบ่งผู้จัด</span>
                      <input type="number" class="form-control" required v-model="organizer_share" placeholder="10" >
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">ส่วนแบ่งผู้ดูแล</span>
                      <input type="number" class="form-control" required v-model="salespersonshare" placeholder="5" >
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">ส่วนแบ่งช่างภาพ</span>
                      <input type="number" class="form-control" required v-model="photographer_share" placeholder="60" >
                    </div>
                  </div>
                  <!-- <div class="col-sm-12 col-lg-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">รหัสผู้ดูแลกิจกรรม</span>
                      <input type="number" class="form-control" minlength="4" v-model="sale_agent" maxlength="4" required placeholder="1" >
                    </div>
                  </div> -->
                  <div class="col-sm-12">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">Share Drive</span>
                      <input type="text" class="form-control" v-model="google_drive" placeholder="URL share drive" >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">กลุ่ม Line</span>
                      <input type="text" class="form-control" v-model="line_group" placeholder="URL line กลุ่ม" >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">Hashtag</span>
                      <textarea class="form-control" id="basic-addon1" rows="3" v-model="hashtag" placeholder="#งานวิ่งxxx #งานวิ่งบ้านๆ"  ></textarea>
                    </div>
                  </div>
                  

                  </div>

                </div>
                <hr>
                <div class="col-sm-12">
                  <b>รูปแบบกิจกรรม</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="form-check form-check-inline">
                   <input class="form-check-input" v-model="event_type" checked required type="radio"
                    name="inlineRadioOptions" id="inlineRadio1" value="MHAO">
                  <label class="form-check-label" for="inlineRadio1">
                    เหมาๆ
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="event_type" type="radio" required name="inlineRadioOptions"
                    id="inlineRadio2" value="OPEN">
                  <label class="form-check-label" for="inlineRadio2">
                    โอเพ่น
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="event_type" type="radio" required name="inlineRadioOptions"
                    id="inlineRadio3" value="HYBRID">
                  <label class="form-check-label" for="inlineRadio3">
                    ไฮบริด
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" v-model="event_type" type="radio" required name="inlineRadioOptions"
                    id="inlineRadio4" value="FREE">
                  <label class="form-check-label" for="inlineRadio4">
                    แจกฟรี
                  </label>
                </div>
                <div class="col-sm-12 col-lg-6">
                    <div class="input-group mb-3" v-if="this.event_type==='MHAO'">
                      <span class="input-group-text" id="basic-addon1">ราคาเริ่มต้น</span>
                      <select class="form-select" required v-model="price_start"  id="floatingSelect">
                        <option value="60">60</option>
                        <option value="65">65</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                        <option value="120">120</option>
                      </select>
                    </div>
                    <div class="input-group mb-3" v-if="this.event_type==='MHAO'">
                      <span class="input-group-text" id="basic-addon1">แพคเกจ 5 ใบ</span>
                      <select class="form-select" required v-model="is_5package_price"  id="floatingSelect">
                        <option v-for="(sub_item, sub_index) in this.package5" :key="sub_index" :value="sub_item.value"> {{ sub_item.name }}</option>
                      </select>
                    </div>

                      <div class="input-group mb-3" v-if="this.event_type==='MHAO'">
                        <span class="input-group-text" id="basic-addon1">ราคาเหมา</span>
                        <input type="number" class="form-control" v-model="mhao_price" required placeholder="400" >
                      </div>
                  
                  <div class="input-group mb-3" v-if="this.event_type==='OPEN' || this.event_type ==='HYBRID'">
                      <span class="input-group-text" id="basic-addon1">ราคาแพคเกจ</span>
                      <select class="form-select" required v-model="package_open"  id="floatingSelect">
                        <option value="V1">V1 [1ใบ:ราคา60, 5:250, 10:390, 20:500]</option>
                        <option value="V2">V2 [1ใบ:ราคา70, 5:299, 10:390, 20:499]</option>
                        <option value="V3">V3 [1ใบ:ราคา70, 5:250, 10:350]</option>
                        <option value="V4">V4 [1ใบ:ราคา70, 5:259, 10:359, 20:459]</option>
                        <option value="V5">V5 [1ใบ:ราคา70, 5:299, 10:359, 20:459]</option>
                      </select>
                    </div>
                  
                  <div class="input-group mb-3" v-if="this.event_type ==='HYBRID'">
                      <span class="input-group-text" id="basic-addon1">เหมาไฮบริด</span>
                      <select class="form-select" required v-model="hybrid_mhao_price"  id="floatingSelect">
                        <option v-for="(sub_item, sub_index) in this.package_hb_mhao" :key="sub_index" :value="sub_item.value"> {{ sub_item.name }}</option>
                      </select>
                  </div>
                </div>
                </div>
                <hr>
                <div class="col-sm-12 col-lg-12">
                  <div class="mb-3">
                    
                    <label for="coverFile" class="form-label">ภาพปกงาน (ขนาด 1200X512px)และชื่อ cover.jpg เท่านั้น  <a href="https://drive.google.com/file/d/18RV6xVkN8Y-FBp6OGq_87oMiMKE3MhLr/view?usp=sharing" target="_blank"><u>ตัวอย่าง</u></a></label>
                    
                    <br>
                    <input required type="file" accept="image/jpg" @change="previewImage" class="form-control-file" id="coverFile" >
                    <template v-if="preview">
                      <h5 v-if="image.name!== 'cover.jpg'" style="color: red;">ชื่อไฟล์ต้องเป็น cover.jpg เท่านั้น!</h5>
                      <img :src="preview" class="img-fluid" />
                    </template>
                    
                  </div>
                </div>

                <!-- <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <label for="formFile" class="form-label">กรอบภาพ แนวนอน</label>
                    <img src="https://rnth.s3.ap-southeast-1.amazonaws.com/nakhonchiangrairun2025/cover.jpg" class="img-fluid" alt="...">
                    <input class="form-control" type="file" id="formFile" >
                  </div>
                
                  <div class="col-sm-6 col-lg-6">
                    <label for="formFile" class="form-label">กรอบภาพ แนวตั้ง</label>
                    <img src="https://rnth.s3.ap-southeast-1.amazonaws.com/nakhonchiangrairun2025/cover.jpg" class="img-fluid" alt="...">
                    <input class="form-control" type="file" id="formFile" >
              
                </div>
              </div> -->
                
                <hr>
                <div class="row">
                    <div class="d-flex justify-content-center">
                      <button type="submit" style="cursor: pointer"
                        class="btn btn-primary btn-lg">สร้างกิจกรรม</button>&nbsp;
                      <button  type="reset" style="cursor: pointer" class="btn btn-primary btn-lg" > Clear </button> 
                    </div>
                  </div>
                <span style="size: 8px;text-align-last: right;">*หากต้องการ ราคาหรือรูปแบบที่มากกว่าที่กำหนดสามารถแจ้งได้เลยครับ</span>
              </form>
            </div>
          </div>
          </div>
        </div>
        <!-- <AllCampaignsCard /> -->

      </div>
    </div>
  </div>
  <div v-if="this.user.role_name === 'photographer'">
    <div class="d-flex justify-content-center">this page is not authorized</div>
  </div>
</template>
<style scoped>
.table-fit {
  width: 1px;
}
</style>
<script>
import axios from "axios";
const FormData = require('form-data');
export default {
  data() {
    return {
      user: {},
      token: localStorage.getItem("jwt"),
      event_type: 'MHAO',
      preview: null,
      image: null,
      check_duplicate_message: "",
      event_info: {},
      title: "",
      event_key: "",
      event_date: "",
      on_sale_start: "",
      on_sale_end: "",
      province: "",
      system_share: 25,
      organizer_share: 5,
      salespersonshare: 5,
      photographer_share: 65,
      sale_agent: 1,
      google_drive: "",
      line_group: "",
      hashtag: "",
      is_free_download: "N",
      is_mhao: "N",
      mhao_price: 0,
      is_5package: "N",
      is_5package_price: 0,
      price_start: 60,
      package_open: "V1",
      is_hybrid: "N",
      hybrid_mhao_price: 0,

      package5: {},
      package_hb_mhao: {}


    };
  },
  async created(){
    this.token = localStorage.getItem("jwt")
  },
  async mounted() {
    try {
      this.user = JSON.parse(localStorage.getItem("user"));

      await this.getInfo();
      if (this.user.role_name === "system") {
       
        //this.getLatestOrder();
       
      }
    } catch (error) {
      alert("พบข้อผิดพลาด กรุณาโหลดหน้าใหม่");
    }
  },
  methods: {
    async getInfo(){
      const headers = {
         'Authorization': 'Bearer ' + this.token
      };
      const response = await axios.get(
        "v1/utils/v2-event-create-info",
        null
      );
      //console.log("response::::",response)
      if (response.data.status === "success") {

        this.event_date = response.data.body[0].event_date
        this.on_sale_start = response.data.body[0].event_date_time
        this.on_sale_end = response.data.body[0].next_event_date_time

        this.package5 = response.data.package5
        this.package_hb_mhao = response.data.package_hb_mhao

        //console.log("this.on_sale_end:"+this.on_sale_end)
      }
    },

    async create_event(){
      if(this.event_key.length > 5 ){

        let data_check = {
          event_key: this.event_key
        };

          const headers_check = {
          
        };

        const response = await axios.post("v1/utils/event-isduplicate",data_check,{ headers_check });
        if (response.data.status === "success") {
          if(response.data.body.is_duplicate === "Y"){
            this.check_duplicate_message = "url ซ้ำ! กรุณาตรวจสอบอีกครั้ง"
            alert('url ซ้ำ! กรุณาตรวจสอบอีกครั้ง')
            return false;
          }else{
            this.check_duplicate_message = ""
          }
        } else {
          alert("พบข้อผิดพลาด "+response.data.message);
        }

        let data = new FormData();
        
        const file = document.querySelector('input[type="file"]').files[0];

        data.append('cover', file);
        data.append('event_date', this.event_date);
        data.append('on_sale_start', this.on_sale_start);
        data.append('on_sale_end', this.on_sale_end);
        data.append('title', this.title.trim());
        data.append('province', this.province.trim());
        data.append('event_key', this.event_key.trim());
        data.append('system_share', this.system_share);
        data.append('organizer_share', this.organizer_share);
        data.append('photographer_share', this.photographer_share);
        data.append('salespersonshare', this.salespersonshare);
        data.append('sale_agent', 1);
        data.append('google_drive', this.google_drive);
        data.append('line_group', this.line_group);
        data.append('hashtag', this.hashtag);

        if(this.event_type === 'MHAO'){
          data.append('is_mhao', "Y");
          data.append('price_start', this.price_start);
          if(this.is_5package_price === 0){
            data.append('is_5package', "N");
            data.append('is_5package_price', this.is_5package_price);
          }else{
            data.append('is_5package', "Y");
            data.append('is_5package_price', this.is_5package_price);
          }
          data.append('mhao_price', this.mhao_price);

          if(this.mhao_price <=100){
            alert("กรุณาป้อนราคาเหมา มากกว่า 100 บาท")
            return false;
          }
          
        }

        if(this.event_type === 'OPEN'){
          data.append('package_open', this.package_open);

          data.append('is_free_download', "N");
          data.append('is_mhao', "N");
          data.append('is_hybrid', "N");
        }

        if(this.event_type === 'HYBRID'){
          data.append('package_open', this.package_open);
          data.append('is_hybrid', "Y");
          data.append('hybrid_mhao_price', this.hybrid_mhao_price);

          data.append('is_free_download', "N");
          data.append('is_mhao', "N");
        }

        if(this.event_type === 'FREE'){
          data.append('is_free_download', "Y");

          data.append('is_mhao', "N");
          data.append('is_hybrid', "N");
        }


         const headers = {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.token}`
        };
        let resp = await axios.post('/v1/utils/v2-event-create', data, { headers })
          .then((response) => {
            //console.log("response"+JSON.stringify(response))
            if(response.data.status === 'success'){
              alert("สร้างกิจกรรมเรียบร้อย สามารถเข้าตรวจสอบได้ที่ https://running.in.th/"+this.event_key)
              this.$router.replace("/event-adjustment/"+this.event_key);
            }else{
              alert("พบข้อผิดพลาด กรุณาตรวจสอบอีกครั้ง!")
            }
            
          })
          .catch((error) => {
            alert('พบข้อผิดพลาด: '+error)
          });

          console.log("resp:"+JSON.stringify(resp))

        // const headers = {
         
        // };
        // const response = await axios.post("v1/utils/event-create",data,{ headers });
        // console.log("response:",response)
        // if (response.data.status === "success") {
        //   // if(response.data.body.is_duplicate === "Y"){
        //   //   this.check_duplicate_message = "url ซ้ำ! กรุณาตรวจสอบอีกครั้ง"
        //   // }
        // } else {
        //   alert("พบข้อผิดพลาด "+response.data.message);
        // }
      }
    },
    async check_duplicate_event(){
      //alert(this.event_key)
      //console.log(this.event_key.length)
      if(this.event_key.length > 5 ){
        let data = {
          event_key: this.event_key
        };

        const headers = {
         
      };
        const response = await axios.post("v1/utils/event-isduplicate",data,{ headers });
        if (response.data.status === "success") {
          if(response.data.body.is_duplicate === "Y"){
            this.check_duplicate_message = "url ซ้ำ! กรุณาตรวจสอบอีกครั้ง"
          }else{
            this.check_duplicate_message = ""
          }
        } else {
          this.check_duplicate_message = response.data.message
          alert("พบข้อผิดพลาด "+response.data.message);
        }
      }
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
    }
    
  },
};
</script>
