import { defineStore } from "pinia";

import { ref, computed } from 'vue'

import axios from 'axios'

export const useUploadStore = defineStore('useUploadStore', () => {

  const selectedFiles = ref([]);

  const isUploading = computed(() =>
    selectedFiles.value.some((file) => file.status == "uploading")

  );

  const pending_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "pending").length

  );

  const failed_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "failed").length

  );

  const uploading_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "uploading").length

  );

  const success_count = computed(() =>
    selectedFiles.value.filter(file => file.status == "success").length

  );

  const onSelectFiles = (event) => {
    const target = event.target;
    if (target.files === null) {
      return;
    }

    clearFiles();

    Array.from(target.files).forEach((file) => {
      selectedFiles.value.push({
        file: file,
        percentage: 0,
        status: "pending",
      });
    });
  };

  const clearFiles = () => (selectedFiles.value = []);

  const uploadFile = (file, onUploadProgress, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download,is_mhao,is_hybrid) => {

    let check_data = {
      event_id: event_id,
      photographer_id: user_id,
      original_file_name: file.name
    }
    return axios.post("v1/upload/image-isduplicate", check_data)
    .then((response) => {
      if(response.data.is_duplicate === 'Y'){
        return "skip"
      }else{

        
        //---------------------
        //console.log(file.name+": upload")
        let formData = new FormData();

    formData.append("file", file);
    formData.append('photographer_id', user_id);
    formData.append('event_id', event_id);
    formData.append('event_key', event_key);
    formData.append('event_watermark_hori', event_watermark_hori);
    formData.append('event_watermark_vertical', event_watermark_vertical);
    formData.append('is_free_download', is_free_download);
    formData.append('is_add_watermark_for_free_download', is_add_watermark_for_free_download);
    formData.append('is_mhao', is_mhao);
    formData.append('is_hybrid', is_hybrid);
    
    
    
    //console.log("file size:"+file.size)
    //console.log("file server_upload_url_aws:"+server_upload_url_aws)
    //console.log("file server_upload_url_text:"+server_upload_url_text)
    
   // let fileSize = Number(file.size/10000);
    

        //1048576
        if(Number(file.size/10000) < 470){
          //upload aws
          return axios.post(server_upload_url_aws, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
          });
        }else{
          //upload default server
          //console.log("upload to :"+server_upload_url_text)
          //return axios.post(server_upload_url_text, formData, {
          //  headers: {
          //    "Content-Type": "multipart/form-data",
          //  },
          //  onUploadProgress,
          //});


          
        let presigned_data = {
          event_key: event_key,
          file_name: file.name,
          file_type: "image/jpg"
        }
        return axios.post("v1/upload/presigned-url", presigned_data,)
        .then((presigned_response) => {
          //let new_file_name = presigned_response.data.file_name
          return axios.put(presigned_response.data.url, file, {
            headers: {
              "Content-Type": "image/jpg",
              "Content-Disposition": `inline; filename="${presigned_response.data.file_name}"`,
            },
            onUploadProgress,
          })
          .then((put_response) => {

            let prepare_data = {
              photographer_id: user_id,
              event_key: event_key,
              event_id: event_id,
              event_watermark_hori: event_watermark_hori,
              event_watermark_vertical: event_watermark_vertical,
              is_free_download: is_free_download,
              is_add_watermark_for_free_download: is_add_watermark_for_free_download,
              is_mhao: is_mhao,
              is_hybrid: is_hybrid,
              new_file_name: presigned_response.data.file_name,
              original_file_name: file.name,
              stage_id: 0
            }
            return axios.post("v1/upload/prepare-data", prepare_data)
            .then((prepare_response) => {
              //console.log(new Date().toISOString() +"prepare-data: "+file.name)
            });
          });
        });
        }
      }

      
    });
    // .then((uploadResponse) => {
    //   console.log("Step 2 success:", uploadResponse.data);
    // })
    // .catch((error) => {
    //   console.error("Error occurred in one of the steps:", error);
    // });

    
  
  };

  const sleep_only = async () => {

    return new Promise((resolve, reject) => {
      setTimeout(() => {


        resolve(true)
      }, 20)

    });


  }

  const sleep_upload_slow_upload = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {


        resolve(true)
      }, 500)

    });


  }

  const call_upload_file = (file, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download,is_add_watermark_for_free_download,is_mhao,is_hybrid) => {

    return new Promise((resolve, reject) => {

      //console.log("uploadFile: " + file.file.name)
      file.status = "uploading";
      file.percentage = 0;
      uploadFile(file.file, (event) => {
        file.percentage = Math.round((100 * event.loaded) / event.total);
      }, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download,is_add_watermark_for_free_download,is_mhao,is_hybrid)
        .then((response) => {
          file.status = "success";
          resolve(true)
        })
        .catch(() => {
          file.status = "failed";
          resolve(false)
        });

    });


  }

  const uploadSelectedFiles = async (event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download,is_mhao,is_hybrid,credit_balance) => {

    
    //let check_uploading = selectedFiles.value.some((file) => file.status == "uploading")
    //let check_pending = selectedFiles.value.filter(file => file.status == "pending").length;
    if(is_hybrid === 'Y'){
      let check_pending = selectedFiles.value.filter(file => file.status == "pending").length 
      if(check_pending > credit_balance){
        alert("เครดิตสำหรับการ upload ไม่เพียงพอกรุณาเติมเครดิต")
        return false;
      }
    }
    
    let check_failed = selectedFiles.value.filter(file => file.status == "failed").length
    let check_success = selectedFiles.value.filter(file => file.status == "success").length
    if (check_success === selectedFiles.value.length) {
      if(check_success===0){
        alert("เลือกไฟล์อัปโหลด และกดเริ่มอัปโหลด")
      }else{
        alert("ไฟล์ Upload สำเร็จท้ั้งหมด")
      }
      

    } else {

      if (check_failed > 0) {
        //retry only fial
        let counter = 0
        let array_size = selectedFiles.value.length;

        while (counter < array_size) {
          let _uploading_count = selectedFiles.value.filter(file => file.status == "uploading").length
          if(_uploading_count <= 14){

            if (selectedFiles.value.length > 0) {
              
              let file = selectedFiles.value[counter];
              if(file.status !== 'success'){
                let _sleep_r = call_upload_file(file, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download,is_mhao,is_hybrid)
                await sleep_only()
              }
            }
            counter++
        }else{
          await sleep_upload_slow_upload();
        }

        }
        
      } else {

        let counter = 0;
        let array_size = selectedFiles.value.length;
        let thead_upload = 20;
        if(Number(selectedFiles.value[0].file.size/10000) > 900){
          thead_upload = 13;
        }
        while (counter < array_size) {
          //console.log("counter in while loop:" + counter)
          let _uploading_count = selectedFiles.value.filter(file => file.status == "uploading").length
          //console.log("_uploading_count:" + _uploading_count)

          if(_uploading_count <= thead_upload){

            if (selectedFiles.value.length > 0) {
              
              let file = selectedFiles.value[counter];
              if(file.status !== 'success'){
                let _sleep_r = call_upload_file(file, event_key, event_watermark_hori, event_watermark_vertical, user_id, event_id,server_upload_url_text,server_upload_url_aws,is_free_download, is_add_watermark_for_free_download,is_mhao,is_hybrid)
                //console.log("while result counter:[" + counter + "]" + _sleep_r)
                let _sleep_only_r = await sleep_only()
              }
            }
            counter++
        }else{
          await sleep_upload_slow_upload();
        }

        }

      }

    }

  }



  return { selectedFiles, isUploading, pending_count, uploading_count, failed_count, success_count, onSelectFiles, clearFiles, uploadSelectedFiles }
});